var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "설비 고장 목록",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            gridHeight: _vm.grid.height,
            editable: _vm.editable,
            hideBottom: true,
            isExcelDown: false,
            filtering: false,
            isFullScreen: false,
            columnSetting: false,
            selection: "multiple",
            rowKey: "equipmentBreakdownId",
          },
          on: { "table-data-change": _vm.tableDataChange },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "phenomenons"
                    ? [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            codeGroupCd: "WO_BD_PHENOMENON_CD",
                            valueText: "codeName",
                            valueKey: "code",
                            isArray: true,
                            label: "",
                            name: "phenomenons",
                          },
                          on: {
                            datachange: function ($event) {
                              return _vm.datachange(props.row)
                            },
                          },
                          model: {
                            value: props.row.phenomenons,
                            callback: function ($$v) {
                              _vm.$set(props.row, "phenomenons", $$v)
                            },
                            expression: "props.row.phenomenons",
                          },
                        }),
                      ]
                    : _vm._e(),
                  col.name === "causes"
                    ? [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            codeGroupCd: "WO_BD_CAUSE_CD",
                            valueText: "codeName",
                            valueKey: "code",
                            isArray: true,
                            label: "",
                            name: "causes",
                          },
                          on: {
                            datachange: function ($event) {
                              return _vm.datachange(props.row)
                            },
                          },
                          model: {
                            value: props.row.causes,
                            callback: function ($$v) {
                              _vm.$set(props.row, "causes", $$v)
                            },
                            expression: "props.row.causes",
                          },
                        }),
                      ]
                    : _vm._e(),
                  col.name === "actions"
                    ? [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            codeGroupCd: "WO_BD_ACTION_CD",
                            valueText: "codeName",
                            isArray: true,
                            valueKey: "code",
                            label: "",
                            name: "actions",
                          },
                          on: {
                            datachange: function ($event) {
                              return _vm.datachange(props.row)
                            },
                          },
                          model: {
                            value: props.row.actions,
                            callback: function ($$v) {
                              _vm.$set(props.row, "actions", $$v)
                            },
                            expression: "props.row.actions",
                          },
                        }),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "추가", icon: "add" },
                        on: { btnClicked: _vm.addRow },
                      })
                    : _vm._e(),
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: {
                          url: _vm.saveUrl,
                          isSubmit: _vm.isSave,
                          param: _vm.grid.data,
                          mappingType: "PUT",
                          label: "저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveData,
                          btnCallback: _vm.saveCallback,
                        },
                      })
                    : _vm._e(),
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "삭제", icon: "remove" },
                        on: { btnClicked: _vm.removeRow },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }